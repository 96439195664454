<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="使用说明"/>

    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        }
    }
</script>

<style scoped>
</style>
